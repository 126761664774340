$(function(){

  /****************************************
   * 共通(common)
   ****************************************/

  /**
   * スムーズスクロール
   */
  (function () {
    $('a[href^="#"]').click(function () {
      const speed = 400;
      const href = $(this).attr('href');
      const target = $(href == "#" || href == "" ? "html" : href);
      const header_h = $('#header').height() + 40;
      const position = target.offset().top - header_h;

      $('body, html').animate({
        scrollTop: position
      }, {
        duration: speed,
        easing: 'easeOutCubic'
      });
      return false;
    });
  }).call(this);


  /**
   * グロナビメニュー(SPのみ)
   */
  (function () {
    $('#js-nav-triger').on('click', function(){
      if ( $('#js-header-gnav').hasClass('js-isOpen') ) {
        $('#js-header-gnav').removeClass('js-isOpen');
        $('.menu-btn__line').removeClass('js-isLine-close');
        $('#menu-btn-txt').text("Menu");
      } else {
        // ナビをオープンした状態
        $('#js-header-gnav').addClass('js-isOpen');
        $('.menu-btn__line').addClass('js-isLine-close');
        $('#menu-btn-txt').text("close");
      }
      return false;
    });
  }).call(this);

});